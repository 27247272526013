// Colors
$primary: #47d2d8;
$secondary: #547473;
$light: #F6F9F9;
$polar: #f3fcfd;
$genoa: #17666a;
$whatsapp: #25d366;


$body-bg: #ffffff;

$header-height: 710px;

$navbar-light-color: white;
$navbar-light-toggler-border-color: white;

$input-placeholder-color: $secondary;

$font-family-base: Roboto, "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif;
